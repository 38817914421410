.shd {
  &#cp {
    .fc {
      &-accent {
        color: map-get($theme-color, accent);
      }
    }
    .fs {
      &-1 {
        font-size: map-get($font-size, x1) !important;
      }
      &-2 {
        font-size: map-get($font-size, x2) !important;
      }
      &-3 {
        font-size: map-get($font-size, x3) !important;
      }
      &-4 {
        font-size: map-get($font-size, x4) !important;
      }
      &-5 {
        font-size: map-get($font-size, x5) !important;
      }
      &-6 {
        font-size: map-get($font-size, x6) !important;
      }
    }
    .fw {
      &-400 {
        font-weight: 400 !important;
      }
      &-700 {
        font-weight: 700 !important;
      }
    }
    .ta {
      &-l {
        text-align: left;
      }
      &-r {
        text-align: right;
      }
      &-c {
        text-align: center;
      }
    }
    .m {
      &t {
        &-1 {
          margin-top: map-get($spacing, x1);
        }
        &-2 {
          margin-top: map-get($spacing, x2);
        }
        &-3 {
          margin-top: map-get($spacing, x3);
        }
        &-4 {
          margin-top: map-get($spacing, x4);
        }
        &-5 {
          margin-top: map-get($spacing, x5);
        }
      }
      &r {
        &-1 {
          margin-right: map-get($spacing, x1);
        }
        &-2 {
          margin-right: map-get($spacing, x2);
        }
        &-3 {
          margin-right: map-get($spacing, x3);
        }
        &-4 {
          margin-right: map-get($spacing, x4);
        }
        &-5 {
          margin-right: map-get($spacing, x5);
        }
      }
      &b {
        &-1 {
          margin-bottom: map-get($spacing, x1);
        }
        &-2 {
          margin-bottom: map-get($spacing, x2);
        }
        &-3 {
          margin-bottom: map-get($spacing, x3);
        }
        &-4 {
          margin-bottom: map-get($spacing, x4);
        }
        &-5 {
          margin-bottom: map-get($spacing, x5);
        }
      }
      &l {
        &-1 {
          margin-left: map-get($spacing, x1);
        }
        &-2 {
          margin-left: map-get($spacing, x2);
        }
        &-3 {
          margin-left: map-get($spacing, x3);
        }
        &-4 {
          margin-left: map-get($spacing, x4);
        }
        &-5 {
          margin-left: map-get($spacing, x5);
        }
      }
    }
    .p {
      &t {
        &-1 {
          padding-top: map-get($spacing, x1);
        }
        &-2 {
          padding-top: map-get($spacing, x2);
        }
        &-3 {
          padding-top: map-get($spacing, x3);
        }
        &-4 {
          padding-top: map-get($spacing, x4);
        }
        &-5 {
          padding-top: map-get($spacing, x5);
        }
      }
      &r {
        &-1 {
          padding-right: map-get($spacing, x1);
        }
        &-2 {
          padding-right: map-get($spacing, x2);
        }
        &-3 {
          padding-right: map-get($spacing, x3);
        }
        &-4 {
          padding-right: map-get($spacing, x4);
        }
        &-5 {
          padding-right: map-get($spacing, x5);
        }
      }
      &b {
        &-1 {
          padding-bottom: map-get($spacing, x1);
        }
        &-2 {
          padding-bottom: map-get($spacing, x2);
        }
        &-3 {
          padding-bottom: map-get($spacing, x3);
        }
        &-4 {
          padding-bottom: map-get($spacing, x4);
        }
        &-5 {
          padding-bottom: map-get($spacing, x5);
        }
      }
      &l {
        &-1 {
          padding-left: map-get($spacing, x1);
        }
        &-2 {
          padding-left: map-get($spacing, x2);
        }
        &-3 {
          padding-left: map-get($spacing, x3);
        }
        &-4 {
          padding-left: map-get($spacing, x4);
        }
        &-5 {
          padding-left: map-get($spacing, x5);
        }
      }
    }
    .w {
      &-0 {
        width: map-get($width, 0);
      }
      &-25 {
        width: map-get($width, 25);
      }
      &-50 {
        width: map-get($width, 50);
      }
      &-75 {
        width: map-get($width, 75);
      }
      &-100 {
        width: map-get($width, 100);
      }
      @include mq(md) {
        &-md {
          &-0 {
            width: map-get($width, 0);
          }
          &-25 {
            width: map-get($width, 25);
          }
          &-50 {
            width: map-get($width, 50);
          }
          &-75 {
            width: map-get($width, 75);
          }
          &-100 {
            width: map-get($width, 100);
          }
        }
      }
    }
    .justify-content {
      &-center {
        justify-content: center !important;
      }
      &-space-around {
        justify-content: space-around !important;
      }
    }
    .align-items {
      &-center {
        align-items: center;
      }
    }
  }
}
