@charset "UTF-8";
/* カスタマ イズ用CSS */
.shd--btn--cta--red a {
  background-color: #c1272d;
  color: #fff !important;
  font-size: 20px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 75px;
  padding: 15px;
  position: relative;
}

.shd#cp {
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
}
.shd#cp p {
  margin-bottom: 10px;
}
.shd#cp dl {
  margin-bottom: 30px;
}
.shd#cp dl dt {
  font-weight: 700;
  margin-bottom: 10px;
}
.shd#cp dl dd ul {
  list-style: disc;
  margin-left: 2em;
}
.shd#cp .cta {
  padding: 30px 0;
}
.shd#cp .cta a {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 12vh;
  border-radius: 12vw;
  margin: auto;
  background: #c1272d;
  color: #fff;
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .shd#cp .cta a {
    width: 50%;
    height: 100px;
  }
}
.shd#cp .cta__copy1 {
  display: block;
  font-size: 20px;
  font-weight: 700;
}
.shd#cp .cta__copy2 {
  display: block;
  font-size: 15px;
  font-weight: 400;
}
.shd#cp .section-title {
  margin: 30px 0;
  text-align: center;
}
.shd#cp .section-title__main {
  display: inline-block;
  color: #c1272d;
  font-size: 26px;
  font-weight: 700;
  border-bottom: solid 6px #c1272d;
  padding: 10px 30px;
}
.shd#cp .section-title__sub {
  display: inline-block;
  font-size: 20px;
  font-weight: 700;
  border-bottom: double 6px #000000;
  padding: 10px 0;
}
.shd#cp .border-sand {
  text-align: center;
  border-top: solid 3px #c1272d;
  border-bottom: solid 3px #c1272d;
  padding: 10px 0;
  margin-bottom: 10px;
}
.shd#cp .border-sand span {
  font-size: 20px;
}
.shd#cp .visual-row {
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media screen and (min-width: 768px) {
  .shd#cp .visual-row {
    height: 200px;
    max-width: 50%;
    margin: 0 auto;
  }
}
.shd#cp .flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.shd#cp .flex-2col {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}
.shd#cp .flex-2col > * {
  width: calc(50% - 1vw);
}
.shd#cp .border-box {
  border: 2px solid #666666;
  padding: 2.5vh 2vw;
  margin: 2.5vh 0;
}
.shd#cp .border-box--accent {
  border: 2px solid #c1272d;
  padding: 2.5vh 2vw;
  margin: 2.5vh 0;
}
.shd#cp .bg-dark {
  background-color: #000000;
  padding: 2.5vh 2vw;
  margin: 2.5vh 0;
  color: #fff;
}
.shd#cp .bg-dark .section-title__sub {
  color: #fff;
  border-color: #fff;
}
.shd#cp .bg-accent {
  background-color: #f3c1c3;
  padding: 2.5vh 2vw;
  margin: 2.5vh 0;
}
@media screen and (min-width: 768px) {
  .shd#cp .steplist {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 10px;
  }
}
.shd#cp .steplist .steptitle {
  color: #c1272d;
  font-weight: 700;
  font-size: 18px;
}
.shd#cp .steplist .steptitle--action {
  text-align: center;
}
.shd#cp .steplist .flex {
  display: flex;
  justify-content: space-between;
  position: relative;
  column-gap: 10px;
  margin-bottom: 30px;
  padding: 20px 10px;
  line-height: 1.8;
  letter-spacing: 0.025em;
  border-radius: 1.03245vw;
  background: #fff;
}
@media screen and (min-width: 768px) {
  .shd#cp .steplist .flex {
    width: 45%;
  }
}
.shd#cp .steplist .flex .textarea {
  width: calc(60% - 10px);
}
.shd#cp .steplist .flex figure {
  width: 40%;
}
@media screen and (max-width: 767px) {
  .shd#cp .steplist .flex .arrow {
    position: absolute;
    right: 0;
    bottom: -5.86667vw;
    left: 0;
    width: 5.6vw;
    margin: auto;
  }
}
@media screen and (min-width: 768px) {
  .shd#cp .steplist .flex .arrow {
    position: absolute;
    top: 0;
    right: -1.84366vw;
    bottom: 0;
    width: 1.32743vw;
    margin: auto;
    transform: rotate(-90deg);
  }
}
.shd#cp .steplist .action {
  padding-top: 30px;
  background-color: #fff;
  border-radius: 1.03245vw;
  margin-bottom: 30px;
}
@media screen and (min-width: 768px) {
  .shd#cp .steplist .action {
    display: flex;
    justify-content: space-between;
  }
  .shd#cp .steplist .action div {
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .shd#cp .steplist .action div .steptitle {
    font-size: 30px;
    text-align: left;
  }
}
.shd#cp #notes a {
  display: inline;
}

.shd#cp .fc-accent {
  color: #c1272d;
}
.shd#cp .fs-1 {
  font-size: 30px !important;
}
.shd#cp .fs-2 {
  font-size: 26px !important;
}
.shd#cp .fs-3 {
  font-size: 20px !important;
}
.shd#cp .fs-4 {
  font-size: 18px !important;
}
.shd#cp .fs-5 {
  font-size: 15px !important;
}
.shd#cp .fs-6 {
  font-size: 12px !important;
}
.shd#cp .fw-400 {
  font-weight: 400 !important;
}
.shd#cp .fw-700 {
  font-weight: 700 !important;
}
.shd#cp .ta-l {
  text-align: left;
}
.shd#cp .ta-r {
  text-align: right;
}
.shd#cp .ta-c {
  text-align: center;
}
.shd#cp .mt-1 {
  margin-top: 10px;
}
.shd#cp .mt-2 {
  margin-top: 20px;
}
.shd#cp .mt-3 {
  margin-top: 30px;
}
.shd#cp .mt-4 {
  margin-top: 40px;
}
.shd#cp .mt-5 {
  margin-top: 50px;
}
.shd#cp .mr-1 {
  margin-right: 10px;
}
.shd#cp .mr-2 {
  margin-right: 20px;
}
.shd#cp .mr-3 {
  margin-right: 30px;
}
.shd#cp .mr-4 {
  margin-right: 40px;
}
.shd#cp .mr-5 {
  margin-right: 50px;
}
.shd#cp .mb-1 {
  margin-bottom: 10px;
}
.shd#cp .mb-2 {
  margin-bottom: 20px;
}
.shd#cp .mb-3 {
  margin-bottom: 30px;
}
.shd#cp .mb-4 {
  margin-bottom: 40px;
}
.shd#cp .mb-5 {
  margin-bottom: 50px;
}
.shd#cp .ml-1 {
  margin-left: 10px;
}
.shd#cp .ml-2 {
  margin-left: 20px;
}
.shd#cp .ml-3 {
  margin-left: 30px;
}
.shd#cp .ml-4 {
  margin-left: 40px;
}
.shd#cp .ml-5 {
  margin-left: 50px;
}
.shd#cp .pt-1 {
  padding-top: 10px;
}
.shd#cp .pt-2 {
  padding-top: 20px;
}
.shd#cp .pt-3 {
  padding-top: 30px;
}
.shd#cp .pt-4 {
  padding-top: 40px;
}
.shd#cp .pt-5 {
  padding-top: 50px;
}
.shd#cp .pr-1 {
  padding-right: 10px;
}
.shd#cp .pr-2 {
  padding-right: 20px;
}
.shd#cp .pr-3 {
  padding-right: 30px;
}
.shd#cp .pr-4 {
  padding-right: 40px;
}
.shd#cp .pr-5 {
  padding-right: 50px;
}
.shd#cp .pb-1 {
  padding-bottom: 10px;
}
.shd#cp .pb-2 {
  padding-bottom: 20px;
}
.shd#cp .pb-3 {
  padding-bottom: 30px;
}
.shd#cp .pb-4 {
  padding-bottom: 40px;
}
.shd#cp .pb-5 {
  padding-bottom: 50px;
}
.shd#cp .pl-1 {
  padding-left: 10px;
}
.shd#cp .pl-2 {
  padding-left: 20px;
}
.shd#cp .pl-3 {
  padding-left: 30px;
}
.shd#cp .pl-4 {
  padding-left: 40px;
}
.shd#cp .pl-5 {
  padding-left: 50px;
}
.shd#cp .w-0 {
  width: 0% !important;
}
.shd#cp .w-25 {
  width: 25% !important;
}
.shd#cp .w-50 {
  width: 50% !important;
}
.shd#cp .w-75 {
  width: 75% !important;
}
.shd#cp .w-100 {
  width: 100% !important;
}
@media screen and (min-width: 768px) {
  .shd#cp .w-md-0 {
    width: 0% !important;
  }
  .shd#cp .w-md-25 {
    width: 25% !important;
  }
  .shd#cp .w-md-50 {
    width: 50% !important;
  }
  .shd#cp .w-md-75 {
    width: 75% !important;
  }
  .shd#cp .w-md-100 {
    width: 100% !important;
  }
}
.shd#cp .justify-content-center {
  justify-content: center !important;
}
.shd#cp .justify-content-space-around {
  justify-content: space-around !important;
}
.shd#cp .align-items-center {
  align-items: center;
}