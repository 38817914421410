$breakpoints: (
	// min = ***px 以上のときに適用
	sm: "screen and (min-width: 576px)", // スマホ
	md: "screen and (min-width: 768px)", // タブレット
	lg: "screen and (min-width: 992px)", // ノートPC
	xl: "screen and (min-width: 1200px)", // デスクトップPC
	// max = ***px 以下のときに適用
	maxsm: "screen and (max-width: 575px)", // スマホ
	maxmd: "screen and (max-width: 767px)", // タブレット
	maxlg: "screen and (max-width: 991px)", // ノートPC
	maxxl: "screen and (max-width: 1199px)", // デスクトップPC
);


@mixin mq($breakpoint: md) {
	@media #{map-get($breakpoints, $breakpoint)} {
		@content;
	}
}
