// キャンペーンページ用スタイル

@mixin bg-set() {
  padding: 2.5vh 2vw;
  margin: 2.5vh 0;
}
@mixin flex {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

// --------------------------------------------

.shd {
  &#cp {
    max-width: 768px;
    margin-left: auto;
    margin-right: auto;
    p {
      margin-bottom: map-get($spacing, x1);
    }
    dl {
      margin-bottom: map-get($spacing, x3);
      dt {
        font-weight: 700;
        margin-bottom: map-get($spacing, x1);
      }
      dd {
        ul {
          list-style: disc;
          margin-left: 2em;
        }
      }
    }
    .cta {
      padding: map-get($spacing, x3) 0;
      a {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 12vh;
        border-radius: 12vw;
        margin: auto;
        background: map-get($theme-color, accent);
        color: #fff;
        text-decoration: none;
        @include mq(md) {
          width: 50%;
          height: 100px;
        }
      }
      &__copy1 {
        display: block;
        font-size: map-get($font-size, x3);
        font-weight: 700;
      }
      &__copy2 {
        display: block;
        font-size: map-get($font-size, x5);
        font-weight: 400;
      }
    }
    .section-title {
      margin: map-get($spacing, x3) 0;
      text-align: center;
      &__main {
        display: inline-block;
        color: map-get($theme-color, accent);
        font-size: map-get($font-size, x2);
        font-weight: 700;
        border-bottom: solid 6px map-get($theme-color, accent);
        padding: map-get($spacing, x1) map-get($spacing, x3);
      }
      &__sub {
        display: inline-block;
        font-size: map-get($font-size, x3);
        font-weight: 700;
        border-bottom: double 6px map-get($theme-color, blk);
        padding: map-get($spacing, x1) 0;
      }
    }
    .border-sand {
      text-align: center;
      border-top: solid 3px map-get($theme-color, accent);
      border-bottom: solid 3px map-get($theme-color, accent);
      padding: map-get($spacing, x1) 0;
      margin-bottom: map-get($spacing, x1);
      span {
        font-size: map-get($font-size, x3);
      }
    }
    .visual-row {
      height: 30vh;
      display: flex;
      justify-content: center;
      align-items: center;
      @include mq(md) {
        height: 200px;
        max-width: 50%;
        margin: 0 auto;
      }
    }
    .flex {
      @include flex();
      margin-bottom: map-get($spacing, x2);
      &-2col {
        @include flex();
        margin-bottom: map-get($spacing, x2);
        > * {
          width: calc(50% - 1vw);
        }
      }
    }

    .border-box {
      border: 2px solid map-get($theme-color, bg-blk);
      @include bg-set();
      &--accent {
        border: 2px solid map-get($theme-color, accent);
        @include bg-set();
      }
    }
    .bg {
      &-dark {
        background-color: map-get($theme-color, blk);
        @include bg-set();
        color: #fff;
        .section-title__sub {
          color: #fff;
          border-color: #fff;
        }
      }
      &-accent {
        background-color: map-get($theme-color, bg-accent);
        @include bg-set();
      }
    }
    .steplist {
      @include mq(md) {
        @include flex();
        column-gap: map-get($spacing, x1);
      }
      .steptitle {
        color: map-get($theme-color, accent);
        font-weight: 700;
        font-size: map-get($font-size, x4);
        &--action {
          text-align: center;
        }
      }
      .flex {
        display: flex;
        justify-content: space-between;
        position: relative;
        column-gap: map-get($spacing, x1);
        margin-bottom: map-get($spacing, x3);
        padding: 20px 10px;
        line-height: 1.8;
        letter-spacing: 0.025em;
        border-radius: 1.03245vw;
        background: #fff;
        @include mq(md) {
          width: 45%;
        }
        .textarea {
          width: calc(60% - 10px);
        }
        figure {
          width: 40%;
        }
        @include mq(maxmd) {
          .arrow {
            position: absolute;
            right: 0;
            bottom: -5.86667vw;
            left: 0;
            width: 5.6vw;
            margin: auto;
          }
        }
        @include mq(md) {
          .arrow {
            position: absolute;
            top: 0;
            right: -1.84366vw;
            bottom: 0;
            width: 1.32743vw;
            margin: auto;
            transform: rotate(-90deg);
          }
        }
      }
      .action {
        padding-top: map-get($spacing, x3);
        background-color: map-get($theme-color, wht);
        border-radius: 1.03245vw;
        margin-bottom: map-get($spacing, x3);
        @include mq(md) {
          display: flex;
          justify-content: space-between;
          div {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            .steptitle {
              font-size: map-get($font-size, x1);
              text-align: left;
            }
          }
        }
      }
    }

    #notes {
      a {
        display: inline;
      }
    }
  }
}
