/* カスタマ イズ用CSS */



.shd--btn--cta--red a {
  background-color: map-get($theme-color, 'accent');
  color: #fff !important;
  font-size: 20px;
  font-weight: 700;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 100%;
  height: 75px;
  padding: 15px;
  position: relative;
}